<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add New" }} Venue Hire
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="venue-form">
          <v-select
            label="Customer *"
            :items="filteredCustomers"
            v-model="fields.customer_id"
            item-text="full_name"
            item-value="id"
            :return-object="false"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          >
            <template v-slot:prepend-item>
              <div class="pa-3">
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchTerm"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </div>
            </template>
          </v-select>

          <v-select
            label="Venue *"
            v-model="fields.venue_id"
            :items="venues"
            item-value="id"
            item-text="name"
            :disabled="isNotVenuePage"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('venue_id')"
            :error-messages="errors['venue_id']"
          ></v-select>
          <v-text-field
            label="Date *"
            v-model="fields.date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('date')"
            :error-messages="errors['date']"
          ></v-text-field>
          <v-text-field
            label="Start Time"
            v-model="fields.start_time"
            type="time"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('start_time')"
            :error-messages="errors['start_time']"
          ></v-text-field>
          <v-text-field
            label="Total Attendees"
            v-model="fields.total_attendees"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_attendees')"
            :error-messages="errors['total_attendees']"
          ></v-text-field>
          <v-text-field
            label="Full Name *"
            v-model="fields.customer_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('customer_name')"
            :error-messages="errors['customer_name']"
          ></v-text-field>
          <v-text-field
            label="Email *"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>
          <v-text-field
            label="Phone *"
            v-model="fields.phone"
            type="phone"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>
          <v-text-field
            label="Address Line 1 *"
            v-model="fields.address_line_1"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_line_1')"
            :error-messages="errors['address_line_1']"
          ></v-text-field>
          <v-text-field
            label="Address Line 2"
            v-model="fields.address_line_2"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_line_2')"
            :error-messages="errors['address_line_2']"
          ></v-text-field>
          <v-text-field
            label="City *"
            v-model="fields.address_city"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_city')"
            :error-messages="errors['address_city']"
          ></v-text-field>
          <v-text-field
            label="Postcode *"
            v-model="fields.address_postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_postcode')"
            :error-messages="errors['address_postcode']"
          ></v-text-field>
          <v-textarea
            label="Booking Notes"
            v-model="fields.booking_notes"
            rows="4"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('booking_notes')"
            :error-messages="errors['booking_notes']"
          ></v-textarea>
          <v-text-field
            label="Cost *"
            v-model="fields.cost"
            type="number"
            min="0"
            steps="1"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('cost')"
            :error-messages="errors['cost']"
          ></v-text-field>
          <v-switch
            label="Invoiced?"
            v-model="fields.invoiced"
            inset
            color="green"
            :error="errors.hasOwnProperty('invoiced')"
            :error-messages="errors['invoiced']"
          ></v-switch>
          <v-switch
            label="Booking Has Been Confirmed?"
            v-model="fields.is_confirmed"
            inset
            color="green"
            :error="errors.hasOwnProperty('is_confirmed')"
            :error-messages="errors['is_confirmed']"
          ></v-switch>
          <v-switch
            label="Letter Trail Sent?"
            v-model="fields.letter_trail_sent"
            inset
            color="green"
            :error="errors.hasOwnProperty('letter_trail_sent')"
            :error-messages="errors['letter_trail_sent']"
          ></v-switch>
          <v-card outlined background-color="white" class="mt-6">
            <v-card-subtitle>Optional Extras</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <div v-if="fields.venue_id === null">Please select a venue</div>
              <div v-else>
                <div
                  v-for="(extra, index) in venue.optional_extras"
                  :key="extra.id"
                >
                  <div v-if="extra.always_display == 0">
                    <div v-if="extra.type === 'checkbox'">
                      <v-row>
                        <v-checkbox
                          v-model="fields.extras[extra.id].value"
                          hide-details
                          class="shrink mt-3 ml-3 mr-0"
                        ></v-checkbox>
                        <v-col class="pl-0">
                          <div class="subtitle-1">{{ extra.name }}</div>
                          <div v-if="extra.description" class="caption mb-3">
                            {{ extra.description }}
                          </div>
                          <v-text-field
                            :label="extra.options.label"
                            v-model="fields.extras[extra.id].input"
                            outlined
                            v-if="extra.has_additional_input"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div
                      v-else-if="extra.type === 'multiple-checkboxes'"
                      class="pb-4"
                    >
                      <div class="subtitle-1">{{ extra.name }}</div>
                      <div v-if="extra.description" class="caption mb-3">
                        {{ extra.description }}
                      </div>
                      <v-row>
                        <v-col
                          cols="6"
                          v-for="(option, index) in extra.options"
                          :key="index"
                          class="pt-1 pb-1"
                        >
                          <v-checkbox
                            :label="option.label"
                            v-model="
                              fields.extras[extra.id].value[option.value]
                            "
                            hide-details
                            class="mt-1"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else-if="extra.type === 'incrementer'">
                      <div class="subtitle-1">{{ extra.name }}</div>
                      <div v-if="extra.description" class="caption mb-3">
                        {{ extra.description }}
                      </div>
                      <v-text-field
                        :label="extra.options.label"
                        v-model="fields.extras[extra.id].value"
                        type="number"
                        min="0"
                        :max="extra.options.max"
                        steps="1"
                        outlined
                      ></v-text-field>
                    </div>
                    <v-divider
                      class="mt-4 mb-4"
                      v-if="index !== venue.optional_extras.length - 1"
                    ></v-divider>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="venue-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    isNotVenuePage: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      searchTerm: "",
      event: {},
      fields: {
        customer_id: null,
        date: null,
        start_time: null,
        total_attendees: null,
        invoiced: false,
        letter_trail_sent: false,
        customer_name: null,
        email: null,
        phone: null,
        address_line_1: null,
        address_line_2: null,
        address_city: null,
        address_postcode: null,
        is_confirmed: false,
        venue_id: null,
        cost: null,
        booking_notes: null,
        extras: [],
      },
      errors: {},
    };
  },

  methods: {
    open(event = null) {
      if (this.isNotVenuePage) {
        this.fields.venue_id = parseInt(this.$route.params.venueId);
      }

      if (event !== null) {
        this.event = event;
        this.isEditing = true;
        this.fields.customer_id = event.customer_id;
        this.fields.venue_id = event.venue.id;
        this.fields.date = event.date;
        this.fields.start_time = event.start_time;
        this.fields.total_attendees = event.total_attendees;
        this.fields.invoiced = event.invoiced;
        this.fields.letter_trail_sent = event.letter_trail_sent;
        this.fields.customer_name = event.customer_name;
        this.fields.email = event.email;
        this.fields.phone = event.phone;
        this.fields.address_line_1 = event.address_line_1;
        this.fields.address_line_2 = event.address_line_2;
        this.fields.address_city = event.address_city;
        this.fields.address_postcode = event.address_postcode;
        this.fields.is_confirmed = event.is_confirmed;
        this.fields.cost = event.cost;
        this.fields.booking_notes = event.booking_notes;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let fields = _.cloneDeep(this.fields);
      let extras = {};

      fields.extras.forEach((extra, index) => {
        extras[index] = extra;
      });

      fields.extras = JSON.stringify(extras);

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields,
      };

      if (this.isEditing) {
        payload.bookingId = this.event.id;
      }

      this.$store
        .dispatch("craigtoun/venuesStore/saveBooking", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.event = {};
      this.fields = {
        customer_id: null,
        date: null,
        start_time: null,
        total_attendees: null,
        invoiced: false,
        customer_name: null,
        email: null,
        phone: null,
        address_line_1: null,
        address_line_2: null,
        address_city: null,
        address_postcode: null,
        is_confirmed: false,
        letter_trail_sent: false,
        venue_id: null,
        cost: null,
        booking_notes: null,
        extras: [],
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },

  computed: {
    customers() {
      return this.$store.getters["craigtoun/customersStore/all"];
    },

    filteredCustomers() {
      let customers = this.customers;
      if (this.searchTerm !== "" || this.searchTerm !== null) {
        customers = customers.filter((c) => {
          const name = c.full_name.toLowerCase();

          return name.includes(this.searchTerm.toLowerCase());
        });
      }

      return customers;
    },

    venues() {
      return this.$store.getters["craigtoun/venuesStore/all"];
    },

    venue() {
      if (this.fields.venue_id === null) {
        return null;
      }

      return this.venues.find((v) => {
        return v.id === parseInt(this.fields.venue_id);
      });
    },
  },

  watch: {
    venue(venue) {
      if (!venue) {
        return;
      }
      this.fields.extras = [];

      venue.optional_extras.forEach((extra) => {
        let payload = {
          value: "",
        };

        if (extra.type === "checkbox") {
          payload.value = false;
        } else if (extra.type === "incrementer") {
          payload.value = 0;
        } else if (extra.type === "multiple-checkboxes") {
          payload.value = {};
          extra.options.forEach((option) => {
            payload.value[option.value] = false;
          });
        }

        if (extra.has_additional_input) {
          payload.input = "";
        }

        if (
          this.isEditing &&
          this.event.extras.some((e) => e.id === extra.id)
        ) {
          const chosenExtra = this.event.extras.find((e) => e.id === extra.id);
          const options = JSON.parse(chosenExtra.pivot.options);

          if (extra.type === "checkbox") {
            payload.value = true;
          } else if (extra.type === "incrementer") {
            payload.value = options.quantity;
          } else if (extra.type === "multiple-checkboxes") {
            options.selected.forEach((option) => {
              payload.value[option] = true;
            });
          }

          if (extra.has_additional_input) {
            payload.input = options.input;
          }
        }

        this.fields.extras[extra.id] = payload;
      });
    },
  },
};
</script>
